import { Property } from 'Shared/Services/Properties/Models/Property';
import { TFunction } from 'next-i18next';

export function createPropertyAuctionLabelColor(data: Property) {
  switch (data.auction?.status) {
    case 'coming_soon':
      return '#d92792';

    case 'created':
      return '#d92792';

    case 'under_evaluation':
    case 'buy_now_under_evaluation':
    case 'buy_now_only_under_evaluation':
      return '#c61811';

    case 'running':
      return '#9e99c3';

    case 'closed_with_no_bids':
    case 'closed_with_winner_and_reserved_price_met':
    case 'closed_with_winner_and_reserved_price_not_met':
      return '#c61811';

    case 'sold':
    case 'sold_for_buy_now':
      return '#c61811';

    case 'not_sold':
      return '#c61811';

    default:
      return '#a80874';
  }
}

export function createPropertyAuctionLabel(data: Property, t: TFunction) {
  switch (data.auction?.status) {
    case 'coming_soon':
      return t('status.comingSoon', { ns: 'auctions' });

    case 'created':
      return t('status.created', { ns: 'auctions' });

    case 'under_evaluation':
    case 'buy_now_under_evaluation':
    case 'buy_now_only_under_evaluation':
      return t('status.offerInProgress', { ns: 'auctions' });

    case 'running':
      return t('status.running', { ns: 'auctions' });

    case 'closed_with_no_bids':
    case 'closed_with_winner_and_reserved_price_met':
    case 'closed_with_winner_and_reserved_price_not_met':
      return t('status.closed', { ns: 'auctions' });

    case 'sold':
    case 'sold_for_buy_now':
      return t('status.sold', { ns: 'auctions' });

    case 'not_sold':
      return t('status.notSold', { ns: 'auctions' });

    default:
      return null;
  }
}
