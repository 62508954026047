import { useDeps } from 'Shared/Application/DepsProvider';
import { useLoop } from 'loop/context';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TbBath,
  TbDoor,
  TbHeart,
  TbHeartFilled,
  TbRuler,
  TbStairs,
} from 'react-icons/tb';
import { toast } from 'sonner';
import { Property } from '../../../Shared/Services/Properties/Models/Property';
import {
  createPropertyAuctionLabel,
  createPropertyAuctionLabelColor,
} from '../Utils/createPropertyAuctionLabel';
import {
  createPropertyPrice,
  createPropertySellLabel,
} from '../Utils/createPropertyPrice';
import { ImageSlider } from './ImageSlider';
import { cn } from './Input';
import { SRPCardCountdown } from './SRPCardCountdown';

export function SRPCard({
  data,
  mini = false,
}: {
  data: Property;
  mini?: boolean;
}) {
  const router = useRouter();
  const { session } = useLoop();
  const { hubspot } = useDeps();
  const { t } = useTranslation(['auctions', 'search']);
  const [isFavorite, setIsFavorite] = React.useState(false);

  const onFavorite = () => {
    if (session?.user) {
      if (!isFavorite) {
        setIsFavorite(true);

        toast.success(t('cards.favorite', { ns: 'search' }));

        hubspot
          .createDealFavorite({
            email: session?.user.email,
            productHsId: data.productHubspotId!,
          })
          .then(() => console.log('createDealFavorite: Success.'))
          .catch(() => console.log('createDealFavorite: Error.'));
      } else {
        setIsFavorite(false);
      }
    } else {
      router.push('/v2/signin');
    }
  };

  const locale = router.locale || 'en';
  const isRunning = data.auction?.status === 'running';
  const isCreated = data.auction?.status === 'created';
  const elaspseIn = data.auction!.endDateUtc;

  const formatter = new Intl.DateTimeFormat(router.locale, {
    dateStyle: 'short',
    timeStyle: 'short',
  });

  const auctionLabel = createPropertyAuctionLabel(data, t);
  const images = data.multimedia?.images?.map((x) => x.url!) || [];

  return (
    <div className="h-full flex flex-col relative isolate">
      <a
        href={`/${locale}/property/${data.id}`}
        className="absolute inset-0 z-10"
      />
      <div className="rounded-lg border-zinc-200 bg-white p-0 shadow-sm h-full flex flex-col">
        <div className="aspect-video rounded-t-lg bg-neutral-100 relative overflow-hidden">
          <ImageSlider items={images} />

          <div data-chunk="code" className="absolute bottom-1 right-5">
            <span className="bg-white border-b border-zinc-200 text-zinc-400 px-2 py-1 rounded-t-lg text-xs font-space">
              {data.code}
            </span>
          </div>

          <div className="absolute top-[5px] right-[5px] z-20">
            <button
              aria-label="Favorite"
              onClick={() => onFavorite()}
              className="flex p-0 h-12 w-12 items-center justify-center bg-transparent"
            >
              {isFavorite ? (
                <TbHeartFilled
                  size={24}
                  strokeWidth={2}
                  className="shrink-0 text-white shadow-sm"
                />
              ) : (
                <TbHeart
                  size={24}
                  strokeWidth={2}
                  stroke="#ffffff"
                  fill="rgb(0 0 0 / 0.5)"
                  className="shrink-0"
                />
              )}
            </button>
          </div>

          {auctionLabel && (
            <div
              data-chunk="auction-label"
              className={cn(
                'absolute left-0 px-2 text-xs font-semibold uppercase text-white py-1 bg-sky-500 rounded-r-md',
                mini ? 'top-36' : 'top-4'
              )}
              style={{
                backgroundColor: createPropertyAuctionLabelColor(data),
              }}
            >
              {auctionLabel}
            </div>
          )}
        </div>
        <div className="space-y-4 border-l border-r border-zinc-200 border-b border-b-zinc-100 p-4 flex flex-col flex-1">
          <div className="space-y-2 flex-1">
            <h3
              className={cn(
                'font-semibold leading-tight text-lg tracking-tight font-space',
                mini ? 'text-base' : 'text-lg',
                mini ? 'leading-none' : 'leading-tight',
                mini ? 'truncate' : 'line-clamp-2'
              )}
            >
              {data.descriptions?.title || 'No Title'}
            </h3>
            <div className="text-xs leading-none text-zinc-500 font-medium flex items-center gap-2">
              <span className="truncate">{data.geo.address}</span>
            </div>
          </div>

          {!mini && (
            <div className="flex text-sm mt-auto divide-x divide-zinc-200">
              {data.features?.rooms && data.features?.rooms.value !== 0 && (
                <div className="flex items-center gap-2 px-2 first:pl-0">
                  <TbDoor
                    strokeWidth={1.5}
                    size={20}
                    className="shrink-0 text-zinc-400"
                  />
                  <span className="font-medium text-zinc-500">
                    {data.features.rooms.value}
                  </span>
                </div>
              )}

              {data.features?.bathrooms &&
                data.features?.bathrooms.value !== 0 && (
                  <div className="flex items-center gap-2 px-2 first:pl-0">
                    <TbBath
                      strokeWidth={1.5}
                      size={20}
                      className="shrink-0 text-zinc-400"
                    />
                    <span className="font-medium text-zinc-500">
                      {data.features.bathrooms.value}
                    </span>
                  </div>
                )}

              {data.features?.floor && (
                <div className="flex items-center gap-2 px-2 first:pl-0">
                  <TbStairs
                    strokeWidth={1.5}
                    size={20}
                    className="shrink-0 text-zinc-400"
                  />
                  <span className="font-medium text-zinc-500">
                    {data.features.floor.value}
                  </span>
                </div>
              )}

              {data.features?.surface && data.features?.surface.value !== 0 && (
                <div className="flex items-center gap-2 px-2 first:pl-0">
                  <TbRuler
                    strokeWidth={1.5}
                    size={20}
                    className="shrink-0 text-zinc-400"
                  />
                  <span className="font-medium text-zinc-500">
                    {`${data.features.surface.value} ${t('cards.sqm', {
                      ns: 'search',
                    })}`}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="space-y-3 px-4 py-3 border border-t-0 rounded-b-lg">
          <div className="flex items-center justify-between">
            <div className="space-y-1">
              <div className="text-xs text-zinc-700 leading-none">
                {createPropertySellLabel(data, t)}
              </div>
              <div className="text-xl font-semibold leading-none text-zinc-900 font-space tracking-tight">
                {createPropertyPrice(data, t)}
              </div>
            </div>

            {isRunning && (
              <div className="space-y-1 text-right">
                <div className="text-xs text-zinc-700 leading-none">
                  {t('labels.timeRemaining')}
                </div>
                <div className="text-xl font-semibold leading-none text-zinc-900 font-space tracking-tight">
                  <SRPCardCountdown targetDate={new Date(elaspseIn)} />
                </div>
              </div>
            )}

            {isCreated && (
              <div className="space-y-0.5 text-right">
                <div className="text-xs text-zinc-700 leading-none">
                  Auction Starts
                </div>
                <div className="text-xl font-semibold leading-none text-zinc-900 font-space tracking-tight">
                  {formatter.format(new Date(data.auction!.startDateUtc))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
