import { createCurrencyFormatter } from 'Shared/Utils/createCurrencyFormatter';
import { differenceInWeeks } from 'date-fns';
import { TFunction } from 'i18next';
import { Property } from '../../../Shared/Services/Properties/Models/Property';

export function createPropertyPrice(data: Property, t: TFunction) {
  const FALLBACK_LABEL = 'N.D.';

  const currencyFmt = createCurrencyFormatter();

  if (data.economics?.reserved) {
    return t('prices.reserved', { ns: 'auctions' });
  }

  switch (data.auction?.status) {
    case 'buy_now_under_evaluation':
    case 'buy_now_only_under_evaluation':
    case 'under_evaluation':
    case 'only_buy_now':
    case 'coming_soon':
    case 'closed_with_no_bids':
    case 'closed_with_winner_and_reserved_price_not_met':
    case 'closed_with_winner_and_reserved_price_met': {
      if (!data.auction?.buyNowPrice?.amount) {
        return FALLBACK_LABEL;
      }

      return currencyFmt.format(data.auction.buyNowPrice.amount);
    }

    case 'running':
    case 'created': {
      const selected = data.auction?.highestBid
        ? data.auction.highestBid.offer.amount
        : data.auction?.startingPrice.amount;

      return currencyFmt.format(selected);
    }

    case 'sold':
    case 'sold_for_buy_now':
    case 'sold_for_buy_now_only': {
      if (data.auction) {
        const diff = differenceInWeeks(
          new Date(data.auction.startDate),
          new Date(data.auction?.updatedAt)
        );

        if (diff < 3) return `3 ${t('prices.weeks', { ns: 'auctions' })}`;
        if (diff > 16) return `16 ${t('prices.weeks', { ns: 'auctions' })}`;

        return `${diff} ${t('prices.weeks', { ns: 'auctions' })}`;
      }

      return FALLBACK_LABEL;
    }

    default:
      return FALLBACK_LABEL;
  }
}

export function createPropertySellLabel(data: Property, t: TFunction) {
  const FALLBACK_LABEL = 'N.D.';

  if (
    data.features?.contractType?.rawValue === 'affitto' &&
    data.auction?.status === 'sold'
  ) {
    return t('labels.rentedIn', { ns: 'auctions' });
  }

  if (data.features?.contractType?.rawValue === 'affitto') {
    return t('labels.rent', { ns: 'auctions' });
  }

  if (data.foreclosureSale?.enabled) {
    return t('labels.legal', { ns: 'auctions' });
  }

  switch (data.auction?.status) {
    case 'running':
      return t('labels.running', {
        bids: data.auction.bids?.length,
        ns: 'auctions',
      });
    case 'created':
      return t('labels.created', { ns: 'auctions' });
    case 'sold':
    case 'sold_for_buy_now':
      return t('labels.soldIn', { ns: 'auctions' });
    case 'closed_with_no_bids':
    case 'closed_with_winner_and_reserved_price_not_met':
    case 'closed_with_winner_and_reserved_price_met':
      return t('labels.closed', { ns: 'auctions' });
    case 'only_buy_now':
    case 'coming_soon':
    case 'buy_now_under_evaluation':
    case 'buy_now_only_under_evaluation':
    case 'under_evaluation':
      return t('labels.buyNow', { ns: 'auctions' });
    default:
      return FALLBACK_LABEL;
  }
}
