import Image from 'next/image';
import { useState } from 'react';
import { TbChevronLeft, TbChevronRight } from 'react-icons/tb';

export function ImageSlider({ items }: { items: string[] }) {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const handleNext = () => {
    setCurrentSlideIndex((prev: number) => (prev === items.length - 1 ? 0 : prev + 1));
  };

  const handlePrev = () => {
    setCurrentSlideIndex((prev: number) => (prev === 0 ? items.length - 1 : prev - 1));
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <div className="image-slider w-full aspect-video relative">
      <Image
        layout="fill"
        alt="Slide"
        src={items[currentSlideIndex]}
        className="aspect-video rounded-t-lg object-cover"
        sizes="650px, (min-width: 640px) 500px, (min-width: 1024px) 650px, (min-width: 1280px) 500px, 900px"
      />

      <button
        onClick={handlePrev}
        className="absolute z-20 top-1/2 left-4 transform -translate-y-1/2 hover:bg-gray-50/20 text-white h-8 w-8 text-xl flex items-center justify-center rounded-lg"
      >
        <TbChevronLeft className="shrink-0" />
      </button>
      <button
        onClick={handleNext}
        className="absolute z-20 top-1/2 right-4 transform -translate-y-1/2 hover:bg-gray-50/20 text-white h-8 w-8 text-xl flex items-center justify-center rounded-lg"
      >
        <TbChevronRight className="shrink-0" />
      </button>
    </div>
  );
}
