import * as React from 'react';

interface Props {
  targetDate: Date;
}

export function SRPCardCountdown({ targetDate }: Props) {
  const [timeLeft, setTimeLeft] = React.useState({
    total: 0,
    hours: 0,
    seconds: 0,
    minutes: 0,
    days: 0,
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft(targetDate);
      setTimeLeft(newTimeLeft);

      if (newTimeLeft.total <= 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [targetDate]);

  const calculateTimeLeft = (targetDate: Date) => {
    const now = new Date().getTime();
    const difference = targetDate.getTime() - now;

    if (difference <= 0) {
      return {
        total: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
      total: difference,
      days,
      hours,
      minutes,
      seconds,
    };
  };

  if (timeLeft.days > 0) {
    return (
      <div>
        <span>{timeLeft.days}D </span>
        <span>{timeLeft.hours}h</span>
      </div>
    );
  }

  if (timeLeft.hours > 0) {
    return (
      <div>
        <span>{timeLeft.hours}h </span>
        <span>{timeLeft.minutes}m</span>
      </div>
    );
  }

  return (
    <div>
      <span>{timeLeft.minutes}m </span>
      <span>{timeLeft.seconds}s</span>
    </div>
  );
}
