export function createCurrencyFormatter(opts?: Intl.NumberFormatOptions) {
  const formatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...opts,
  });

  return formatter;
}
